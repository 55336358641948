import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { apiAdminLoadPreviouslySold } from "../../../store/actions/actions";
import PaginationDetail from "../../PaginationDetail";
import NumberPlate from "../../NumberPlate";

const PreviouslySoldList = (props) => {
  const [previouslySoldResults, setPreviouslySoldResults] = useState([]);
  const [meta, setMeta] = useState(null);

  useEffect(() => {
    props
      .apiAdminLoadPreviouslySold(props.filters)
      .then(({ response }) => {
        setPreviouslySoldResults(response.data.previously_sold_registrations);
        setMeta(response.data.meta);
      })
      .catch((error) => {});
  }, [props.filters, props.counter]);

  const previousPageHandler = (e) => {
    if (props.filters.page > 1) {
      props.updateMainState({
        ...props.filters,
        page: props.filters.page - 1,
      });
    }
  };

  const nextPageHandler = (e) => {
    if (meta && props.filters.page < meta.total_pages) {
      props.updateMainState({
        ...props.filters,
        page: props.filters.page + 1,
      });
    }
  };

  let previouslySoldItems = null;
  if (previouslySoldResults.length > 0) {
    previouslySoldItems = previouslySoldResults.map((r, index) => {
      return (
        <tr key={index}>
          <td className="">
            <strong>
              <NumberPlate mark={r.mark} />
            </strong>
          </td>
          <td className="">{r.uploaded_at}</td>
          <td className="">{r.invoice_id.toString().padStart(6, "0")}</td>
          <td className="">{r.invoice_date}</td>
        </tr>
      );
    });
  }

  if (previouslySoldItems && previouslySoldItems.length > 0) {
    return (
      <React.Fragment>
        <table className="table table-striped table-bordered">
          <thead>
            <tr>
              <th scope="col">Registration</th>
              <th scope="col" className="">
                Uploaded Date
              </th>
              <th scope="col">Invoice</th>
              <th scope="col" className="">
                Invoice Date
              </th>
            </tr>
          </thead>
          <tbody>{previouslySoldItems}</tbody>
        </table>
        <PaginationDetail
          meta={meta}
          onPreviousClick={previousPageHandler}
          onNextClick={nextPageHandler}
        />
      </React.Fragment>
    );
  }
  return <div></div>;
};

const mapStateToProps = (state) => {
  return {
    filters: state.previouslySoldFilters.filters,
  };
};

// Add ability to get state and manipulate it.
const mapDispatchToProps = (dispatch) => {
  return {
    apiAdminLoadPreviouslySold: (f) => dispatch(apiAdminLoadPreviouslySold(f)),
    updateMainState: (f) =>
      dispatch({ type: "SET_PREVIOUSLY_SOLD_FILTERS", payload: f }),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PreviouslySoldList)
);
