const initAuth = () => {
  return {
    filters: {
      registration: "",
      page: 1,
    },
  };
};

function previouslySoldFilters(state = initAuth(), action) {
  switch (action.type) {
    case "SET_PREVIOUSLY_SOLD_FILTERS":
      return { ...state, ...{ filters: action.payload } };
    case "RESET_PREVIOUSLY_SOLD_FILTERS":
      return {
        ...state,
        ...{
          filters: {
            registration: "",
            page: 1,
          },
        },
      };
    default:
      return state;
  }
}

export default previouslySoldFilters;
