import React, { Component } from "react";
import { ArrowRight } from "react-bootstrap-icons";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { apiLogout } from "../../../store/actions/actions";

class MainNav extends Component {
  logoutHandler = (e) => {
    e.preventDefault();
    this.props
      .apiLogout()
      .then(({ response }) => {})
      .catch((error) => {});
  };
  holdingPageHandler = (e) => {
    e.preventDefault();
  };
  render() {
    let initials = localStorage.getItem("initials");
    let nav = (
      <nav className="navbar fixed-top navbar-expand-lg navbar-dark bg-dark">
        <a className="navbar-brand" href="/admin">
          T12ADE
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto">
            <li className="nav-item">
              <Link to="/admin/orders" className="nav-link mr-2">
                Orders
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/admin/offers" className="nav-link mr-2">
                Offers
              </Link>
            </li>
            <li className="nav-item dropdown">
              <Link
                to="/admin/registrations"
                className="nav-link mr-2 dropdown-toggle"
                id="regDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Registrations
              </Link>
              <div className="dropdown-menu" aria-labelledby="regDropdown">
                <Link to="/admin/registrations" className="dropdown-item">
                  Registration List
                </Link>
                <Link
                  to="/admin/registrations/expiries"
                  className="dropdown-item"
                >
                  Certificate Expiry Dates
                </Link>
              </div>
            </li>
            <li className="nav-item">
              <Link to="/admin/companies" className="nav-link mr-2">
                Companies
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/admin/users" className="nav-link mr-2">
                Users
              </Link>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link mr-2 dropdown-toggle"
                href="/"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Reporting
              </a>
              <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                <Link to="/admin/reporting" className="dropdown-item">
                  Searches
                </Link>
                <Link
                  to="/admin/reporting/previously-sold"
                  className="dropdown-item"
                >
                  Listed But Previously Sold
                </Link>
              </div>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link mr-2 dropdown-toggle"
                href="/"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Setup
              </a>
              <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                <Link to="/admin/pages" className="dropdown-item">
                  Pages
                </Link>
                <Link to="/admin/emails" className="dropdown-item">
                  Emails
                </Link>
                <div className="dropdown-divider"></div>
                <Link to="/admin/settings/statuses" className="dropdown-item">
                  Statuses
                </Link>
                <Link
                  to="/admin/settings/status_notes"
                  className="dropdown-item"
                >
                  Status notes
                </Link>
                <Link
                  to="/admin/settings/transfer_fees"
                  className="dropdown-item"
                >
                  Transfer fees
                </Link>
                <div className="dropdown-divider"></div>
                <span
                  className="dropdown-item cursor-pointer"
                  onClick={this.holdingPageHandler}
                >
                  Toggle Holding Page
                </span>
              </div>
            </li>

            {/* <li className="nav-item dropdown">
            <a className="nav-link mr-2 dropdown-toggle" href="/" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Tools
            </a>
            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
              <Link to="/admin/dealer_registrations" className="dropdown-item">
                Dealer Registrations
              </Link>
            </div>
          </li> */}
          </ul>
          <ul className="navbar-nav">
            <li className="nav-item dropdown">
              <span
                className=" text-black"
                id="dropdownMenuButton"
                data-toggle="dropdown"
              >
                <button className="btn-circle btn-sm ">
                  <span className="initials">{initials}</span>
                </button>
              </span>
              <div
                className="dropdown-menu dropdown-menu-right"
                aria-labelledby="navbarDropdown"
              >
                <Link to={"/registrations"} className="dropdown-item">
                  Public Site <ArrowRight />
                </Link>
                <div class="dropdown-divider"></div>
                <span
                  className="dropdown-item cursor-pointer"
                  onClick={this.logoutHandler}
                >
                  Logout
                </span>
              </div>
            </li>
          </ul>
        </div>
      </nav>
    );
    return nav;
  }
}

// Add ability to get state and manipulate it.
const mapStateToProps = (state) => {
  return {
    loggedIn: state.authentication.loggedIn,
    pages: state.pages.pageList,
    pagesLoaded: state.pages.loaded,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    apiLogout: () => dispatch(apiLogout()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MainNav);
