import AdminRedirecter from "./../../features/admin/Redirecter";
import AdminOrders from "./../../features/admin/Orders/Orders";
import AdminOffers from "./../../features/admin/Offers/Offers";
import AdminRegistrations from "./../../features/admin/Registrations/Registrations";
import AdminExpiries from "./../../features/admin/Registrations/Expiries";
import AdminDealerRegistrations from "./../../features/admin/DealerRegistrations/DealerRegistrations";
import AdminReporting from "./../../features/admin/Reporting/Reporting";
import AdminPreviouslySold from "./../../features/admin/Reporting/PreviouslySold";
import AdminSettings from "./../../features/admin/Settings/Settings";
import AdminStatuses from "../../features/admin/Settings/Statuses/Statuses";
import AdminAddStatus from "../../features/admin/Settings/Statuses/AddStatus";
import AdminEditStatus from "../../features/admin/Settings/Statuses/EditStatus";
import AdminStatusNotes from "./../../features/admin/Settings/StatusNotes";
import AdminTransferFees from "./../../features/admin/Settings/TransferFees";
import AdminUsers from "./../../features/admin/Users/Users";
import AdminAddUser from "./../../features/admin/Users/AddUser";
import AdminEditUser from "./../../features/admin/Users/EditUser";
import AdminCompanies from "./../../features/admin/Companies/Companies";
import AdminAddCompany from "./../../features/admin/Companies/AddCompany";
import AdminEditCompany from "./../../features/admin/Companies/EditCompany";
import AdminAddRegistration from "./../../features/admin/Registrations/AddRegistration";
import AdminEditRegistration from "./../../features/admin/Registrations/EditRegistration";
import AdminPages from "./../../features/admin/Pages/Pages";
import AdminAddPage from "./../../features/admin/Pages/AddPage";
import AdminEditPage from "./../../features/admin/Pages/EditPage";
import AdminEmails from "../../features/admin/Emails/Emails";
import AdminEditEmail from "../../features/admin/Emails/EditEmail";

function registerRoute(route) {
  const addRoute = Object.assign(
    {},
    {
      // Component to render the route
      component: null,

      // Name reference for the route
      name: null,

      // Path to match the route
      path: null,

      // Whether the user should be authenticated to view the route
      auth: true,

      // Whether the user should be an admin to view the route
      admin: true,

      // Whether the route should match the path exactly
      exact: true,
    },
    route
  );

  return addRoute;
}

const adminRoutes = [
  registerRoute({
    component: AdminRedirecter,
    name: "AdminRedirecter",
    path: "/admin",
    headerTitle: "Admin | T12ADE",
    auth: true,
    admin: true,
    redirect: "/login",
  }),
  registerRoute({
    component: AdminOrders,
    name: "AdminOrders",
    path: "/admin/orders",
    headerTitle: "Orders | Admin | T12ADE",
    auth: true,
    admin: true,
    redirect: "/login",
  }),
  registerRoute({
    component: AdminOffers,
    name: "AdminOffers",
    path: "/admin/offers",
    headerTitle: "Offers | Admin | T12ADE",
    auth: true,
    admin: true,
    redirect: "/login",
  }),
  registerRoute({
    component: AdminRegistrations,
    name: "AdminRegistrations",
    path: "/admin/registrations",
    headerTitle: "Registrations | Admin | T12ADE",
    auth: true,
    admin: true,
  }),
  registerRoute({
    component: AdminExpiries,
    name: "AdminRegistrations",
    path: "/admin/registrations/expiries",
    headerTitle: "Registration Expiries| Admin | T12ADE",
    auth: true,
    admin: true,
  }),
  registerRoute({
    component: AdminDealerRegistrations,
    name: "AdminDealerRegistrations",
    path: "/admin/dealer_registrations",
    headerTitle: "Dealer Registrations | Admin | T12ADE",
    auth: true,
    admin: true,
  }),
  registerRoute({
    component: AdminAddRegistration,
    name: "AdminAddRegistrations",
    path: "/admin/registrations/add",
    headerTitle: "Add Registration | Admin | T12ADE",
    auth: true,
    admin: true,
  }),
  registerRoute({
    component: AdminEditRegistration,
    name: "AdminEditRegistrations",
    path: "/admin/registrations/:id",
    headerTitle: "Edit Registration | Admin | T12ADE",
    auth: true,
    admin: true,
  }),
  registerRoute({
    component: AdminUsers,
    name: "AdminUsers",
    path: "/admin/users",
    headerTitle: "Users | Admin | T12ADE",
    auth: true,
    admin: true,
    redirect: "/login",
  }),
  registerRoute({
    component: AdminAddUser,
    name: "AdminAddUser",
    path: "/admin/users/new",
    headerTitle: "Add User | Admin | T12ADE",
    auth: true,
    admin: true,
    redirect: "/login",
  }),
  registerRoute({
    component: AdminEditUser,
    name: "AdminEditUser",
    path: "/admin/users/:id",
    headerTitle: "Edit User | Admin | T12ADE",
    auth: true,
    admin: true,
    redirect: "/login",
  }),
  registerRoute({
    component: AdminCompanies,
    name: "AdminCompanies",
    path: "/admin/companies",
    headerTitle: "Companies | Admin | T12ADE",
    auth: true,
    admin: true,
    redirect: "/login",
  }),
  registerRoute({
    component: AdminAddCompany,
    name: "AdminAddCompany",
    path: "/admin/companies/new",
    headerTitle: "Add Company | Admin | T12ADE",
    auth: true,
    admin: true,
    redirect: "/login",
  }),
  registerRoute({
    component: AdminEditCompany,
    name: "AdminEditCompany",
    path: "/admin/companies/:id",
    headerTitle: "Edit Company | Admin | T12ADE",
    auth: true,
    admin: true,
    redirect: "/login",
  }),
  registerRoute({
    component: AdminPages,
    name: "AdminPages",
    path: "/admin/pages",
    headerTitle: "Pages | Admin | T12ADE",
    auth: true,
    admin: true,
    redirect: "/login",
  }),
  registerRoute({
    component: AdminAddPage,
    name: "AdminAddPage",
    path: "/admin/pages/add",
    headerTitle: "Add Page | Admin | T12ADE",
    auth: true,
    admin: true,
  }),
  registerRoute({
    component: AdminEditPage,
    name: "AdminEditPage",
    path: "/admin/pages/:id",
    headerTitle: "Edit Page | Admin | T12ADE",
    auth: true,
    admin: true,
  }),
  registerRoute({
    component: AdminReporting,
    name: "AdminReporting",
    path: "/admin/reporting",
    headerTitle: "Reporting | Admin | T12ADE",
    auth: true,
    admin: true,
    redirect: "/login",
  }),
  registerRoute({
    component: AdminPreviouslySold,
    name: "AdminPreviouslySold",
    path: "/admin/reporting/previously-sold",
    headerTitle: "Reporting | Previously Sold | Admin | T12ADE",
    auth: true,
    admin: true,
    redirect: "/login",
  }),
  registerRoute({
    component: AdminSettings,
    name: "AdminSettings",
    path: "/admin/settings",
    headerTitle: "Settings | Admin | T12ADE",
    auth: true,
    admin: true,
    redirect: "/login",
  }),
  registerRoute({
    component: AdminStatuses,
    name: "AdminStatuses",
    path: "/admin/settings/statuses",
    headerTitle: "Statuses | Admin | T12ADE",
    auth: true,
    admin: true,
    redirect: "/login",
  }),
  registerRoute({
    component: AdminAddStatus,
    name: "AdminAddStatus",
    path: "/admin/settings/statuses/add",
    headerTitle: "Add Status | Admin | T12ADE",
    auth: true,
    admin: true,
    redirect: "/login",
  }),
  registerRoute({
    component: AdminEditStatus,
    name: "AdminEditStatus",
    path: "/admin/settings/statuses/:id",
    headerTitle: "Edit Status | Admin | T12ADE",
    auth: true,
    admin: true,
    redirect: "/login",
  }),
  registerRoute({
    component: AdminStatusNotes,
    name: "AdminStatusNotes",
    path: "/admin/settings/status_notes",
    headerTitle: "Status Notes | Admin | T12ADE",
    auth: true,
    admin: true,
    redirect: "/login",
  }),
  registerRoute({
    component: AdminTransferFees,
    name: "AdminTransferFees",
    path: "/admin/settings/transfer_fees",
    headerTitle: "Transfer Fees - Admin | T12ADE",
    auth: true,
    admin: true,
    redirect: "/login",
  }),
  registerRoute({
    component: AdminEmails,
    name: "AdminEmails",
    path: "/admin/emails",
    headerTitle: "Emails | Admin | T12ADE",
    auth: true,
    admin: true,
    redirect: "/login",
  }),
  registerRoute({
    component: AdminEditEmail,
    name: "AdminEditEmail",
    path: "/admin/emails/:id",
    headerTitle: "Edit Email | Admin | T12ADE",
    auth: true,
    admin: true,
    redirect: "/login",
  }),
];

export default adminRoutes;
