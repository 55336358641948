import axios from "../../axios";
import fileDownload from "js-file-download";

export const toggleHoldingPage = () => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      dispatch({ type: "TOGGLE_KILL_SWITCH", payload: true });
    });
  };
};

export const apiLogin = (u) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      axios
        .post("/v1/auth", { password: u.password, email: u.email })
        .then(({ data }) => {
          dispatch({ type: "SET_LOGGED_IN", payload: data.response.data });
          return resolve(data);
        })
        .catch((error) => {
          return reject(error.response);
        });
    });
  };
};

export const apiGenerateSubscriberResetLink = (u) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      axios
        .post("/v1/auth/reset_link", { email: u.email })
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((error) => {
          return reject(error.response);
        });
    });
  };
};

export const apiLogout = () => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      dispatch({ type: "LOG_OUT", payload: true }).then(() => {
        return resolve(true);
      });
    });
  };
};

export const apiRegistrationSearch = (f) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      axios
        .get("/v1/registrations", { params: f })
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((error) => {
          return reject(error.response);
        });
    });
  };
};

export const apiLoadPages = () => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      axios
        .get("/v1/pages")
        .then(({ data }) => {
          dispatch({ type: "SET_PAGES", payload: data.response.data.pages });
          return resolve(data);
        })
        .catch((error) => {
          return reject(error.response);
        });
    });
  };
};

export const apiLoadInvoices = (reg) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      axios
        .get("/v1/invoices", { params: { reg: reg } })
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((error) => {
          return reject(error.response);
        });
    });
  };
};

export const apiLoadPage = (s) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      axios
        .get("/v1/pages/" + s)
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((error) => {
          return reject(error.response);
        });
    });
  };
};

export const apiLoadRegistration = (m) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      axios
        .get("/v1/registrations/" + m)
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((error) => {
          return reject(error.response);
        });
    });
  };
};

export const apiPurchaseRegistration = (f) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      axios
        .post("/v1/invoices", f)
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((error) => {
          return reject(error.response);
        });
    });
  };
};

export const apiSubmitOffer = (m, p, c) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      axios
        .post("/v1/registrations/" + m + "/offers", {
          offer_price: p,
          comments: c,
        })
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((error) => {
          return reject(error.response);
        });
    });
  };
};

export const apiSubmitEnquiry = (m, e) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      axios
        .post("/v1/registrations/" + m + "/enquiries", { message: e })
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((error) => {
          return reject(error.response);
        });
    });
  };
};

export const apiAdminLoadStatusNotes = () => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      axios
        .get("/v1/me/status_notes")
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((error) => {
          return reject(error.response);
        });
    });
  };
};

export const apiAdminSaveNote = (sn) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      if (sn.id == null) {
        axios
          .post("/v1/me/status_notes", sn)
          .then(({ data }) => {
            return resolve(data);
          })
          .catch((error) => {
            return reject(error.response);
          });
      } else {
        axios
          .put("/v1/me/status_notes/" + sn.id, sn)
          .then(({ data }) => {
            return resolve(data);
          })
          .catch((error) => {
            return reject(error.response);
          });
      }
    });
  };
};

export const apiAdminDeleteNote = (id) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      axios
        .delete("/v1/me/status_notes/" + id)
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((error) => {
          return reject(error.response);
        });
    });
  };
};

export const apiAdminLoadTransferFees = () => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      axios
        .get("/v1/me/transfer_fees")
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((error) => {
          return reject(error.response);
        });
    });
  };
};

export const apiAdminSaveTransferFee = (tf) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      if (tf.id == null) {
        axios
          .post("/v1/me/transfer_fees", tf)
          .then(({ data }) => {
            return resolve(data);
          })
          .catch((error) => {
            return reject(error.response);
          });
      } else {
        axios
          .put("/v1/me/transfer_fees/" + tf.id, tf)
          .then(({ data }) => {
            return resolve(data);
          })
          .catch((error) => {
            return reject(error.response);
          });
      }
    });
  };
};

export const apiAdminDeleteTransferFee = (id) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      axios
        .delete("/v1/me/transfer_fees/" + id)
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((error) => {
          return reject(error.response);
        });
    });
  };
};

export const apiAdminLoadOffers = (f) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      axios
        .get("/v1/me/offers", { params: f })
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((error) => {
          return reject(error.response);
        });
    });
  };
};

export const apiAdminAcceptOffer = (id, message) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      axios
        .post("/v1/me/offers/" + id + "/accept", { message: message })
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((error) => {
          return reject(error.response);
        });
    });
  };
};

export const apiAdminDeclineOffer = (id, message) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      axios
        .post("/v1/me/offers/" + id + "/decline", { message: message })
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((error) => {
          return reject(error.response);
        });
    });
  };
};

export const apiAdminDeleteOffer = (id) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      axios
        .delete("/v1/me/offers/" + id)
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((error) => {
          return reject(error.response);
        });
    });
  };
};

export const apiAdminCounterOffer = (id, price, message) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      axios
        .post("/v1/me/offers/" + id + "/counter", {
          offer_price: price,
          message: message,
        })
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((error) => {
          return reject(error.response);
        });
    });
  };
};

export const apiAdminLoadInvoices = (f) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      axios
        .get("/v1/me/invoices", { params: f })
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((error) => {
          return reject(error.response);
        });
    });
  };
};

export const apiAdminUpdateInvoiceStatuses = (id, d) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      axios
        .put("/v1/me/invoices/" + id, d)
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((error) => {
          return reject(error.response);
        });
    });
  };
};

export const apiAdminCancelInvoice = (id, chargeRestockingFee, message) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      axios
        .delete("/v1/me/invoices/" + id, {
          params: {
            charge_restocking_fee: chargeRestockingFee,
            message: message,
          },
        })
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((error) => {
          return reject(error.response);
        });
    });
  };
};

export const apiAdminLoadCompaniesList = (f) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      axios
        .get("/v1/me/companies/list", { params: f })
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((error) => {
          return reject(error.response);
        });
    });
  };
};

export const apiAdminLoadCompanies = (f) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      axios
        .get("/v1/me/companies", { params: f })
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((error) => {
          return reject(error.response);
        });
    });
  };
};

export const apiAdminLoadCompany = (cid) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      axios
        .get("/v1/me/companies/" + cid)
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((error) => {
          return reject(error.response);
        });
    });
  };
};

export const apiAdminSaveCompany = (c) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      if (c.id === null) {
        axios
          .post("/v1/me/companies", c)
          .then(({ data }) => {
            return resolve(data);
          })
          .catch((error) => {
            return reject(error.response);
          });
      } else {
        axios
          .put("/v1/me/companies/" + c.id, c)
          .then(({ data }) => {
            return resolve(data);
          })
          .catch((error) => {
            return reject(error.response);
          });
      }
    });
  };
};

export const apiAdminEnableCompany = (id) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      axios
        .post("/v1/me/companies/" + id + "/enable")
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((error) => {
          return reject(error.response);
        });
    });
  };
};

export const apiAdminDisableCompany = (id, message) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      axios
        .post("/v1/me/companies/" + id + "/disable", { message: message })
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((error) => {
          return reject(error.response);
        });
    });
  };
};

export const apiAdminLoadSubscribers = (f) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      axios
        .get("/v1/me/subscribers", { params: f })
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((error) => {
          return reject(error.response);
        });
    });
  };
};

export const apiAdminExportSubscribers = (f) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      axios
        .get("/v1/me/subscribers.csv", { params: f })
        .then(({ data }) => {
          fileDownload(data, "users.csv");
        })
        .catch((error) => {
          return reject(error.response);
        });
    });
  };
};

export const apiAdminLoadSubscriber = (sid) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      axios
        .get("/v1/me/subscribers/" + sid)
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((error) => {
          return reject(error.response);
        });
    });
  };
};

export const apiAdminSaveSubscriber = (s) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      if (s.id === null) {
        axios
          .post("/v1/me/subscribers", s)
          .then(({ data }) => {
            return resolve(data);
          })
          .catch((error) => {
            return reject(error.response);
          });
      } else {
        axios
          .put("/v1/me/subscribers/" + s.id, s)
          .then(({ data }) => {
            return resolve(data);
          })
          .catch((error) => {
            return reject(error.response);
          });
      }
    });
  };
};

export const apiAdminEnableUser = (id, message) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      axios
        .post("/v1/me/subscribers/" + id + "/enable", { message: message })
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((error) => {
          return reject(error.response);
        });
    });
  };
};

export const apiAdminSendStockList = (message) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      axios
        .get("/v1/me/subscribers/send_stock_list", {
          params: { message: message },
        })
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((error) => {
          return reject(error.response);
        });
    });
  };
};

export const apiAdminDisableUser = (id, message) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      axios
        .post("/v1/me/subscribers/" + id + "/disable", { message: message })
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((error) => {
          return reject(error.response);
        });
    });
  };
};

export const apiAdminLoadStatuses = () => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      axios
        .get("/v1/me/statuses")
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((error) => {
          return reject(error.response);
        });
    });
  };
};

export const apiAdminRegistrationSearch = (f) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      axios
        .get("/v1/me/registrations", { params: f })
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((error) => {
          return reject(error.response);
        });
    });
  };
};

export const apiAdminExpiries = (page) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      axios
        .get("/v1/me/registrations/expiries", { params: { page: page } })
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((error) => {
          return reject(error.response);
        });
    });
  };
};

export const apiAdminDealerRegistrationSearch = (f) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      axios
        .get("/v1/me/dealer_registrations", { params: f })
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((error) => {
          return reject(error.response);
        });
    });
  };
};

export const apiAdminLoadRegistration = (rid) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      axios
        .get("/v1/me/registrations/" + rid)
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((error) => {
          return reject(error.response);
        });
    });
  };
};

export const apiAdminSaveRegistration = (r) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      if (r.id === null) {
        axios
          .post("/v1/me/registrations", r)
          .then(({ data }) => {
            return resolve(data);
          })
          .catch((error) => {
            return reject(error.response);
          });
      } else {
        axios
          .put("/v1/me/registrations/" + r.id, r)
          .then(({ data }) => {
            return resolve(data);
          })
          .catch((error) => {
            return reject(error.response);
          });
      }
    });
  };
};

export const apiAdminLoadRegistrationsMeta = () => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      axios
        .get("/v1/me/registrations/meta")
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((error) => {
          return reject(error.response);
        });
    });
  };
};

export const apiAdminDeleteRegistration = (id, n, m) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      axios
        .delete("/v1/me/registrations/" + id, { params: { notify: n, msg: m } })
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((error) => {
          return reject(error.response);
        });
    });
  };
};

export const apiAdminExportRegistrations = (f) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      axios
        .get("/v1/me/registrations.xlsx", { params: f, responseType: "blob" })
        .then(({ data }) => {
          fileDownload(data, "registrations.xlsx");
          resolve(true);
        })
        .catch((error) => {
          return reject(error.response);
        });
    });
  };
};

export const apiAdminExportExpiries = (f) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      axios
        .get("/v1/me/registrations/expiries.xlsx", {
          params: f,
          responseType: "blob",
        })
        .then(({ data }) => {
          fileDownload(data, "expiries.xlsx");
          resolve(true);
        })
        .catch((error) => {
          return reject(error.response);
        });
    });
  };
};

export const apiAdminImportRegistrations = (d) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      axios
        .post("/v1/me/registrations/import", d)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          return reject(error.response);
        });
    });
  };
};

export const apiAdminExportOrders = (f) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      axios
        .get("/v1/me/invoices.xlsx", { params: f, responseType: "blob" })
        .then(({ data }) => {
          fileDownload(data, "invoices.xlsx");
          resolve(true);
        })
        .catch((error) => {
          return reject(error.response);
        });
    });
  };
};

export const apiAdminExportOffers = (f) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      axios
        .get("/v1/me/offers.xlsx", { params: f, responseType: "blob" })
        .then(({ data }) => {
          fileDownload(data, "offers.xlsx");
          resolve(true);
        })
        .catch((error) => {
          return reject(error.response);
        });
    });
  };
};

export const apiLoadStatuses = () => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      axios
        .get("/v1/me/statuses")
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((error) => {
          return reject(error.response);
        });
    });
  };
};

export const apiAdminLoadEvents = (f) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      axios
        .get("/v1/me/events", { params: f })
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((error) => {
          return reject(error.response);
        });
    });
  };
};

export const apiAdminLoadPreviouslySold = (f) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      axios
        .get("/v1/me/previously_sold_registrations", { params: f })
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((error) => {
          return reject(error.response);
        });
    });
  };
};

export const apiExportRegistrations = (f, fmt) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      axios
        .get("/v1/registrations/download." + fmt, {
          params: f,
          responseType: "blob",
        })
        .then(({ data }) => {
          fileDownload(data, "T12ADE_Stocklist." + fmt);
          resolve(true);
        })
        .catch((error) => {
          return reject(error.response);
        });
    });
  };
};

export const apiAdminLoadPages = () => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      axios
        .get("/v1/me/pages/")
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((error) => {
          return reject(error.response);
        });
    });
  };
};

export const apiAdminDeletePage = (id) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      axios
        .delete("/v1/me/pages/" + id)
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((error) => {
          return reject(error.response);
        });
    });
  };
};

export const apiAdminLoadPage = (pid) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      axios
        .get("/v1/me/pages/" + pid)
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((error) => {
          return reject(error.response);
        });
    });
  };
};

export const apiAdminSavePage = (p) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      if (p.id === null) {
        axios
          .post("/v1/me/pages", p)
          .then(({ data }) => {
            return resolve(data);
          })
          .catch((error) => {
            return reject(error.response);
          });
      } else {
        axios
          .put("/v1/me/pages/" + p.id, p)
          .then(({ data }) => {
            return resolve(data);
          })
          .catch((error) => {
            return reject(error.response);
          });
      }
    });
  };
};

export const apiAdminLoadEmailTemplates = () => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      axios
        .get("/v1/me/email_templates/")
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((error) => {
          return reject(error.response);
        });
    });
  };
};

export const apiAdminSaveEmailTemplate = (t) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      axios
        .put("/v1/me/email_templates/" + t.id, t)
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((error) => {
          return reject(error.response);
        });
    });
  };
};

export const apiAdminLoadEmailTemplate = (tid) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      axios
        .get("/v1/me/email_templates/" + tid)
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((error) => {
          return reject(error.response);
        });
    });
  };
};

export const apiAdminSaveStatus = (s) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      if (s.id === null) {
        axios
          .post("/v1/me/statuses", s)
          .then(({ data }) => {
            return resolve(data);
          })
          .catch((error) => {
            return reject(error.response);
          });
      } else {
        axios
          .put("/v1/me/statuses/" + s.id, s)
          .then(({ data }) => {
            return resolve(data);
          })
          .catch((error) => {
            return reject(error.response);
          });
      }
    });
  };
};

export const apiAdminLoadStatus = (sid) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      axios
        .get("/v1/me/statuses/" + sid)
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((error) => {
          return reject(error.response);
        });
    });
  };
};

export const apiAdminDeleteStatus = (id) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      axios
        .delete("/v1/me/statuses/" + id)
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((error) => {
          return reject(error.response);
        });
    });
  };
};

export const apiSaveSubscriber = (t) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      axios
        .put("/v1/subscribers/me", t)
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((error) => {
          return reject(error.response);
        });
    });
  };
};

export const apiSaveSubscriberPassword = (t) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      axios
        .put("/v1/subscribers/me/password", t)
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((error) => {
          return reject(error.response);
        });
    });
  };
};

export const apiResetSubscriberPassword = (u) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      axios
        .put("/v1/auth/reset_password", u)
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((error) => {
          return reject(error.response);
        });
    });
  };
};

export const apiLoadSubscriber = () => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      axios
        .get("/v1/subscribers/me")
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((error) => {
          return reject(error.response);
        });
    });
  };
};

export const apiLoadSubscriberCompany = () => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      axios
        .get("/v1/subscribers/company")
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((error) => {
          return reject(error.response);
        });
    });
  };
};

export const apiSaveSubscriberCompany = (t) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      axios
        .put("/v1/subscribers/company", t)
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((error) => {
          return reject(error.response);
        });
    });
  };
};

export const apiSaveApplication = (t) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      axios
        .post("/v1/apply", t)
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((error) => {
          return reject(error.response);
        });
    });
  };
};

export const apiExportInvoice = (id) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      axios
        .get("/v1/invoices/" + id + "/pdf", { responseType: "blob" })
        .then(({ data }) => {
          fileDownload(data, "invoice_" + id + ".pdf");
          resolve(true);
        })
        .catch((error) => {
          return reject(error.response);
        });
    });
  };
};

export const apiAdminExportInvoice = (id) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      axios
        .get("/v1/me/invoices/" + id + "/pdf", { responseType: "blob" })
        .then(({ data }) => {
          fileDownload(data, "invoice_" + id + ".pdf");
          resolve(true);
        })
        .catch((error) => {
          return reject(error.response);
        });
    });
  };
};
