import React, { useState } from "react";
import { connect } from "react-redux";
import AdminLayout from "../../../components/Layouts/AdminLayout";
import PreviouslySoldList from "../../../components/Admin/Reporting/PreviouslySoldList";
import PreviouslySoldFilters from "../../../components/Admin/Reporting/PreviouslySoldFilters";
import {
  apiAdminExportRegistrations,
  apiAdminImportRegistrations,
} from "../../../store/actions/actions";

const PreviouslySold = (props) => {
  const [refreshCounter, setRefreshCounter] = useState(0);

  return (
    <AdminLayout>
      <div className="container-fluid">
        <div className="row my-4 d-flex align-items-center">
          <div className="col col-md-4">
            <h1 className="">Previously Sold</h1>
          </div>
          <div className="col-md-8 text-md-right"></div>
        </div>
        <div className="">
          <div className="container-full">
            <div className="row">
              <div className="col-md-3 mb-4">
                <PreviouslySoldFilters />
              </div>
              <div className="col-md-9">
                <PreviouslySoldList counter={refreshCounter} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </AdminLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    filters: state.registrationFilters.filters,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    apiAdminExportRegistrations: (f) =>
      dispatch(apiAdminExportRegistrations(f)),
    apiAdminImportRegistrations: (d) =>
      dispatch(apiAdminImportRegistrations(d)),
    notify: (msg) => dispatch({ type: "NOTIFY", msg: msg }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PreviouslySold);
